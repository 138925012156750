// prefer default export if available

const preferDefault = m => m && m.default || m
exports.components = {
  "component---src-views-components-hero-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/4600c6ae-e071-5fee-836e-4fdb8a110844.js" /* webpackChunkName: "component---src-views-components-hero-index-tsx" */).then(preferDefault),
  "component---src-views-components-features-list-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/035d8b4f-ae49-5107-af57-5b51119b5618.js" /* webpackChunkName: "component---src-views-components-features-list-index-tsx" */).then(preferDefault),
  "component---src-views-components-infographics-with-phases-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/6727d43a-3008-571b-be66-3580d195810c.js" /* webpackChunkName: "component---src-views-components-infographics-with-phases-index-tsx" */).then(preferDefault),
  "component---src-views-components-half-content-half-widget-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/84b06199-2532-56f1-be9b-d3a8fa15c504.js" /* webpackChunkName: "component---src-views-components-half-content-half-widget-index-tsx" */).then(preferDefault),
  "component---src-views-components-card-slider-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/211e6a19-8b3d-5d07-8dbc-89963a4b4673.js" /* webpackChunkName: "component---src-views-components-card-slider-index-tsx" */).then(preferDefault),
  "component---src-views-components-toggable-content-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/7d5b4afd-395d-5e1e-bf39-2937c7229cd9.js" /* webpackChunkName: "component---src-views-components-toggable-content-index-tsx" */).then(preferDefault),
  "component---src-views-components-simple-card-list-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/8742d7bd-8846-5f19-9c57-86caab3d1bb9.js" /* webpackChunkName: "component---src-views-components-simple-card-list-index-tsx" */).then(preferDefault),
  "component---src-views-components-icon-menu-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/f0e30b40-12d4-5c21-8d35-b0f3e1005064.js" /* webpackChunkName: "component---src-views-components-icon-menu-index-tsx" */).then(preferDefault),
  "component---src-views-components-media-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/a3bd3ce8-141d-52e7-aab0-692b860917d0.js" /* webpackChunkName: "component---src-views-components-media-index-tsx" */).then(preferDefault),
  "component---src-views-components-textual-content-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/35ec33fe-d500-54fa-812c-23097f2a7036.js" /* webpackChunkName: "component---src-views-components-textual-content-index-tsx" */).then(preferDefault),
  "component---src-views-components-columns-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/0a036629-4213-59c9-af68-db840188a31a.js" /* webpackChunkName: "component---src-views-components-columns-index-tsx" */).then(preferDefault),
  "component---src-views-components-media-text-box-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/6435cacc-b8e6-539b-badf-7413cd4e8f05.js" /* webpackChunkName: "component---src-views-components-media-text-box-index-tsx" */).then(preferDefault),
  "component---src-views-components-timeline-with-images-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/36db5ad5-66de-5d7a-b72d-81f6fcded196.js" /* webpackChunkName: "component---src-views-components-timeline-with-images-index-tsx" */).then(preferDefault),
  "component---src-views-components-icon-box-grid-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/24b3840d-f326-5959-bb8a-80d3e527ae57.js" /* webpackChunkName: "component---src-views-components-icon-box-grid-index-tsx" */).then(preferDefault),
  "component---src-views-components-our-team-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/d432c518-2d97-56ea-99c7-c66446214b0a.js" /* webpackChunkName: "component---src-views-components-our-team-index-tsx" */).then(preferDefault),
  "component---src-views-components-vertical-scroll-slides-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/57eadec1-b1fc-5698-be22-8f158eec2d94.js" /* webpackChunkName: "component---src-views-components-vertical-scroll-slides-index-tsx" */).then(preferDefault),
  "component---src-views-components-faq-section-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/2c445b6c-bc06-535b-9c9b-0f2eb13beb13.js" /* webpackChunkName: "component---src-views-components-faq-section-index-tsx" */).then(preferDefault),
  "component---src-views-components-form-with-media-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/feee18c1-2c93-510d-b7c9-9382357742f4.js" /* webpackChunkName: "component---src-views-components-form-with-media-index-tsx" */).then(preferDefault),
  "component---src-views-components-help-center-flow-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/f0308131-aa51-5992-9e47-8829c793f9db.js" /* webpackChunkName: "component---src-views-components-help-center-flow-index-tsx" */).then(preferDefault),
  "component---src-views-components-faq-list-section-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/b133da5a-7656-5cec-b404-f8f43a1b1bef.js" /* webpackChunkName: "component---src-views-components-faq-list-section-index-tsx" */).then(preferDefault),
  "component---src-views-components-video-section-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/4c2a60a5-40ca-52e7-a61e-7f4680998a5d.js" /* webpackChunkName: "component---src-views-components-video-section-index-tsx" */).then(preferDefault),
  "component---src-views-components-job-listing-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/bdb0f3fd-050c-50e0-9017-765bb11b6e68.js" /* webpackChunkName: "component---src-views-components-job-listing-index-tsx" */).then(preferDefault),
  "component---src-views-components-media-slider-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/aebfa2bc-8a8e-5018-8024-26db0855be2a.js" /* webpackChunkName: "component---src-views-components-media-slider-index-tsx" */).then(preferDefault),
  "component---src-views-components-track-shipment-results-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/8e64b2a5-16f4-5526-9b72-b8c5dd4e485c.js" /* webpackChunkName: "component---src-views-components-track-shipment-results-index-tsx" */).then(preferDefault),
  "component---src-views-components-faqs-contact-details-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/ac872d8b-3b98-591c-aef6-619f73054209.js" /* webpackChunkName: "component---src-views-components-faqs-contact-details-index-tsx" */).then(preferDefault),
  "component---src-views-components-sitemap-index-tsx": () => import("/codebuild/output/src1948084606/src/.cache/gatsby-plugin-graphql-component/components/6b76acb3-50ba-5a73-a41b-06c00b73009c.js" /* webpackChunkName: "component---src-views-components-sitemap-index-tsx" */).then(preferDefault)
}

